import React, { useState, useEffect } from 'react';
import './GalleryUpdate.css';

function GalleryUpdate({ onImageUpload }) {
    const [images, setImages] = useState([]);
    const [file, setFile] = useState(null);
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Set available categories
        setCategories([
            'HOTEL', 'SWIMMING POOL', 'ANURADAPURA', 'POLONNARUWA',
            'FACILITIES', 'SIGIRIYA', 'WATERFALLS', 'SRI LANKA BEACH',
            'KANDY', 'DECEMBER 31ST NIGHT', 'HILL COUNTRY', 'CHRISTMAS',
            'AYURVEDA TREATMENT CENTER', 'TOURS', 'HISTORICAL TOUR',
            'NATURE TOUR'
        ]);

        // Fetch existing images from the backend
        const fetchImages = async () => {
            try {
                const response = await fetch('http://localhost/hotel-bougainvilla/hotel-admin/backend/fetch_images.php');
                const data = await response.json();
                setImages(data);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const handleUpload = async () => {
        if (!file || !category) {
            alert("Please select a file and a category.");
            return;
        }

        const formData = new FormData();
        formData.append('image', file);
        formData.append('category', category);

        try {
            const response = await fetch('http://localhost/hotel-bougainvilla/hotel-admin/backend/upload_image.php', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            if (data.success) {
                const newImage = { src: data.filePath, category };
                setImages([...images, newImage]);

                // Call the callback function to update the gallery
                if (onImageUpload) {
                    onImageUpload(newImage);
                }

                // Reset the file input and category
                setFile(null);
                setCategory('');
            } else {
                alert(`Upload failed: ${data.error}`);
            }
        } catch (error) {
            console.error('Upload error:', error);
            alert('An error occurred while uploading the image.');
        }
    };

    return (
        <div className="gallery-update">
            <h1>Gallery Update</h1>
            <div className="image-upload-section">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                />
                <select value={category} onChange={handleCategoryChange}>
                    <option value="">Select Category</option>
                    {categories.map((cat, index) => (
                        <option key={index} value={cat}>{cat}</option>
                    ))}
                </select>
                <button onClick={handleUpload}>Upload Image</button>
            </div>

            <div className="existing-images">
                <h2>Existing Images</h2>
                <div className="existing-images-grid">
                    {images.map((image, index) => (
                        <div key={index} className="existing-image-item">
                            <img src={`http://localhost/hotel-bougainvilla/hotel-admin/${image.src}`} alt={image.category} />
                            <p>{image.category}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default GalleryUpdate;
