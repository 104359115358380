import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import './Checkout.css';
import ProgressBar from './ProgressBar';
import Footer from '../../components/Footer';

function Checkout() {
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedPayment, setSelectedPayment] = useState('paypal'); // Default selection is PayPal

    // Check if booking details are available
    if (!location.state || !location.state.bookingDetails) {
        // Redirect to the booking page if no details are found
        navigate('/booking');
        return null;
    }

    const { room, guests, bookingDetails } = location.state;

    const handlePaymentChange = (e) => {
        setSelectedPayment(e.target.id);
    };

    const handleCheckout = () => {
        let paymentMethod;
        if (selectedPayment === 'arrival') {
            paymentMethod = 'Payment On Arrival';
        } else if (selectedPayment === 'request') {
            paymentMethod = 'Booking Request';
        }

        if (paymentMethod) {
            // Navigate to the confirmation page
            navigate('/booking/confirmation', {
                state: {
                    room,
                    guests,
                    bookingDetails,
                    paymentMethod,
                }
            });

            // Send booking details to the email
            sendBookingEmail(room, guests, bookingDetails, paymentMethod);
        }
    };

    const getButtonText = () => {
        switch (selectedPayment) {
            case 'paypal':
                return 'Checkout with PayPal';
            case 'arrival':
                return 'Book Now';
            case 'request':
                return 'Send Booking Request';
            default:
                return 'Checkout';
        }
    };

    const sendBookingEmail = (room, guests, bookingDetails, paymentMethod) => {
        const templateParams = {
            firstName: bookingDetails.firstName,
            lastName: bookingDetails.lastName,
            email: bookingDetails.email,
            phone: bookingDetails.phone,
            address: bookingDetails.address,
            city: bookingDetails.city,
            country: bookingDetails.country,
            zip: bookingDetails.zip,
            checkIn: new Date(room.checkIn).toLocaleDateString(),
            checkOut: new Date(room.checkOut).toLocaleDateString(),
            guests: `${guests} Adults + 0 Children`,
            totalPrice: `$${room.totalPrice.toFixed(2)}`,
            paymentMethod: paymentMethod,
            roomName: room.name,
            transactionId: Math.floor(Math.random() * 100000000),
        };

        emailjs.send(
            'service_095b55j', // Replace with your EmailJS service ID
            'template_iqqocze', // Replace with your EmailJS template ID
            templateParams,
            'I0IMHHw7vq_Xg7W8t' // Replace with your EmailJS user ID
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
        }, (error) => {
            console.log('FAILED...', error);
        });
    };

    return (
        <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <ProgressBar currentStep="checkout" />

            <div className="checkout-container">
                <div className="checkout-details">
                    <h2>Booking Details</h2>
                    <div className="details-item">
                        <span className="details-label">Name:</span>
                        <span className="details-value">{bookingDetails.firstName}</span>
                    </div>
                    <div className="details-item">
                        <span className="details-label">Surname:</span>
                        <span className="details-value">{bookingDetails.lastName}</span>
                    </div>
                    <div className="details-item">
                        <span className="details-label">Email:</span>
                        <span className="details-value">{bookingDetails.email}</span>
                    </div>
                    <div className="details-item">
                        <span className="details-label">Phone:</span>
                        <span className="details-value">{bookingDetails.phone}</span>
                    </div>
                    <div className="details-item">
                        <span className="details-label">Address:</span>
                        <span className="details-value">{bookingDetails.address}</span>
                    </div>
                    <div className="details-item">
                        <span className="details-label">City:</span>
                        <span className="details-value">{bookingDetails.city}</span>
                    </div>
                    <div className="details-item">
                        <span className="details-label">Country:</span>
                        <span className="details-value">{bookingDetails.country}</span>
                    </div>
                    <div className="details-item">
                        <span className="details-label">ZIP:</span>
                        <span className="details-value">{bookingDetails.zip}</span>
                    </div>
                    <div className="details-item">
                        <span className="details-label">Check In:</span>
                        <span className="details-value">{new Date(room.checkIn).toLocaleDateString()}</span>
                    </div>
                    <div className="details-item">
                        <span className="details-label">Check Out:</span>
                        <span className="details-value">{new Date(room.checkOut).toLocaleDateString()}</span>
                    </div>
                    <div className="details-item">
                        <span className="details-label">Guests:</span>
                        <span className="details-value">{guests} </span>
                    </div>
                    <div className="details-item">
                        <span className="details-label">Total Price:</span>
                        <span className="details-value">${room.totalPrice.toFixed(2)}</span>
                    </div>
                </div>

                <div className="payment-options">
                    <h2>Payment Options</h2>
                    <div className="payment-methods">
                        <div className="payment-method">
                            <input
                                type="radio"
                                name="payment"
                                id="paypal"
                                checked={selectedPayment === 'paypal'}
                                onChange={handlePaymentChange}
                            />
                            <label htmlFor="paypal">PayPal</label>
                        </div>
                        <div className="payment-method">
                            <input
                                type="radio"
                                name="payment"
                                id="arrival"
                                checked={selectedPayment === 'arrival'}
                                onChange={handlePaymentChange}
                            />
                            <label htmlFor="arrival">Payment On Arrival</label>
                        </div>
                        <div className="payment-method">
                            <input
                                type="radio"
                                name="payment"
                                id="request"
                                checked={selectedPayment === 'request'}
                                onChange={handlePaymentChange}
                            />
                            <label htmlFor="request">Booking Request</label>
                        </div>
                    </div>
                    <button className="checkout-button" onClick={handleCheckout}>
                        {getButtonText()}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Checkout;
