import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icons from 'react-icons/fa';
import './Booking.css';
import ProgressBar from './ProgressBar';
import Footer from '../../components/Footer';

function Booking() {
    const location = useLocation();
    const navigate = useNavigate();

    const [bookingDetails, setBookingDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        zip: '',
        requests: '',
        arrival: 'I do not know',
        coupon: ''
    });

    if (!location.state || !location.state.room) {
        navigate('/');
        return null;
    }

    const { room, guests } = location.state;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBookingDetails(prevState => ({ ...prevState, [name]: value }));
    };

    const handleBooking = (e) => {
        e.preventDefault();

        // Navigate directly to the Checkout page with booking details
        navigate('/booking/checkout', {
            state: {
                room,
                guests,
                bookingDetails
            }
        });
    };

    const ServiceIcon = ({ iconName }) => {
        const IconComponent = Icons[iconName];
        return IconComponent ? <IconComponent /> : null;
    };

    return (
        <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <ProgressBar currentStep="booking" />
            <div className="custom-booking-page-container">
                <div className="custom-booking-summary">
                    <img src={room.image} alt={room.name} className="custom-room-image" />
                    <div className="custom-booking-summary-details">
                        <h2>Selected Room: {room.name}</h2>
                        <p>Check In: {new Date(room.checkIn).toLocaleDateString()}</p>
                        <p>Check Out: {new Date(room.checkOut).toLocaleDateString()}</p>
                        <p>Nights: {room.nights}</p>
                        <p>Guests: {guests} </p>
                        <p>Total Price: ${parseFloat(room.totalPrice).toFixed(2)}</p> {/* Ensure price is a number */}
                        <p>18% VAT ALREADY APPLIED</p>
                        <div className="custom-services-container">
                            {room.services.map((service, index) => (
                                <div className="custom-service-item" key={index}>
                                    <ServiceIcon iconName={service.icon} />
                                    <span>{service.text}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="custom-form-container">
                    <form className="custom-booking-form" onSubmit={handleBooking}>
                        <h1>Booking Details</h1>
                        <label className="custom-label">Name:
                            <input type="text" name="firstName" value={bookingDetails.firstName} onChange={handleChange} required placeholder="First Name" className="custom-input" />
                            <input type="text" name="lastName" value={bookingDetails.lastName} onChange={handleChange} required placeholder="Surname" className="custom-input" />
                        </label>
                        <label className="custom-label">Email:
                            <input type="email" name="email" value={bookingDetails.email} onChange={handleChange} required placeholder="Email" className="custom-input" />
                        </label>
                        <label className="custom-label">Phone:
                            <input type="text" name="phone" value={bookingDetails.phone} onChange={handleChange} required placeholder="Phone" className="custom-input" />
                        </label>
                        <label className="custom-label">Address:
                            <input type="text" name="address" value={bookingDetails.address} onChange={handleChange} placeholder="Address" className="custom-input" />
                            <input type="text" name="city" value={bookingDetails.city} onChange={handleChange} placeholder="City" className="custom-input" />
                            <input type="text" name="country" value={bookingDetails.country} onChange={handleChange} placeholder="Country" className="custom-input" />
                            <input type="text" name="zip" value={bookingDetails.zip} onChange={handleChange} placeholder="ZIP" className="custom-input" />
                        </label>
                        <label className="custom-label">Requests:
                            <textarea name="requests" value={bookingDetails.requests} onChange={handleChange} placeholder="Any special requests?" className="custom-textarea" />
                        </label>
                        <label className="custom-label">Arrival:
                            <select name="arrival" value={bookingDetails.arrival} onChange={handleChange} className="custom-select">
                                <option value="I do not know">I do not know</option>
                                <option value="Morning">Morning</option>
                                <option value="Afternoon">Afternoon</option>
                                <option value="Evening">Evening</option>
                            </select>
                        </label>
                        <label className="custom-terms-label">
                            <input type="checkbox" name="terms" required /> I agree to the <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                        </label>
                        <button type="submit" className="custom-checkout-button">Proceed to Checkout</button>
                    </form>

                    <button className="custom-go-back-button" onClick={() => navigate(-1)}>Go Back</button>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Booking;
