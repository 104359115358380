import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminRegister.css';
import hotelLogo from '../../assets/Navbar/Bougainvilla.png'; // Update this path based on your directory structure

function AdminRegister() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert('Passwords do not match!');
            return;
        }

        try {
            const response = await fetch('http://localhost/hotel-bougainvilla/hotel-admin/backend/register.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();
            if (data.success) {
                alert('Admin registered successfully!');
                navigate('/admin/login');
            } else {
                alert('Registration failed: ' + (data.error || 'Unknown error'));
            }
        } catch (error) {
            alert('An error occurred during registration: ' + error.message);
        }
    };

    return (
        <div className="admin-register-container">
            <div className="admin-register-left">
                <img src={hotelLogo} alt="Hotel Logo" />
                <h2>HOTEL BOUGAINVILLA</h2>
            </div>
            <div className="admin-register-right">
                <div className="admin-register-box">
                    <h1 className="admin-register-title">Admin Register</h1>
                    <form className="admin-register-form" onSubmit={handleRegister}>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="admin-register-input"
                            required
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="admin-register-input"
                            required
                        />
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="admin-register-input"
                            required
                        />
                        <button type="submit" className="admin-register-button">Register</button>
                    </form>
                    <div className="admin-register-link">
                        <p>Already have an account? <a href="/admin/login">Login here</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminRegister;
