import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Ensure you only use useNavigate

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate(); // Use navigate for routing inside context

    const login = () => {
        setIsAuthenticated(true);
        navigate('/admin/dashboard');
    };

    const logout = () => {
        setIsAuthenticated(false);
        navigate('/admin/login');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}
