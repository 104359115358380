import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext'; // Ensure this path is correct
import './AdminDashboard.css';
import hotelLogo from '../../assets/Navbar/Bougainvilla.png'; // Make sure this path is correct

function AdminDashboard() {
    const { logout } = useAuth(); // Access the logout function from AuthContext
    const [latestBookings, setLatestBookings] = useState([]);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await fetch('https://bougainvilla.lk/hotel-bougainvilla/hotel-admin/backend/get_latest_bookings.php');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                if (data.success) {
                    // Ensure total_price is treated as a number
                    const bookings = data.bookings.map(booking => ({
                        ...booking,
                        total_price: parseFloat(booking.total_price)
                    }));
                    setLatestBookings(bookings);
                } else {
                    console.error('Error fetching bookings:', data.error);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        fetchBookings();
    }, []);

    return (
        <div className="admin-dashboard">
            <aside className="sidebar">
                <div className="logo">
                    <img src={hotelLogo} alt="Hotel Bougainvilla" className="hotel-logo" />
                    <h2>BOUGAINVILLA</h2>
                </div>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/rooms/rooms-list">Rooms</Link></li>
                    <li><Link to="/booking">Bookings</Link></li>
                    <li><Link to="/">Website</Link></li>
                    <li><Link to="/admin/login">Admin Users</Link></li>
                    <li><Link to="/admin/gallery-update">Gallery Update</Link></li>
                    <li><Link to="/admin/review-update">Reviews Update</Link></li>
                    <li><button onClick={logout} className="logout-button">Logout</button></li> {/* Logout button */}
                </ul>
            </aside>
            <main className="main-content">
                <header className="admin-header">
                    <h1>Dashboard</h1>
                    <div className="admin-header-right">
                        <a href="/" className="admin-open-site">Open The Main Site</a>
                        <span className="admin-user">Administrator</span>
                    </div>
                </header>
                <section className="admin-overview">
                    <div className="overview-item">
                        <h2>27</h2>
                        <p>Room Types</p>
                    </div>
                </section>
                <section className="latest-bookings">
                    <h2>Latest Bookings</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Room</th>
                                <th>Check In</th>
                                <th>Check Out</th>
                                <th>Total</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {latestBookings.map((booking, index) => (
                                <tr key={index}>
                                    <td>{booking.room_name}</td>
                                    <td>{new Date(booking.check_in).toLocaleDateString()}</td>
                                    <td>{new Date(booking.check_out).toLocaleDateString()}</td>
                                    <td>${booking.total_price.toFixed(2)}</td>
                                    <td>{booking.first_name}</td>
                                    <td>{booking.last_name}</td>
                                    <td>{booking.email}</td>
                                    <td>{booking.phone}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </main>
        </div>
    );
}

export default AdminDashboard;
