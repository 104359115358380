import React, { useState, useEffect } from 'react';
import './Gallery.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import galleryImage from '../assets/Gallery/17.jpeg';

// Import all static images for the gallery
import img1 from '../assets/Gallery/Hotel/DSC_6706.JPG';
import img2 from '../assets/Gallery/Hotel/DSC_6686.JPG';
import img3 from '../assets/Gallery/Hotel/DSC_6096.JPG';
import img4 from '../assets/Gallery/Hotel/DSC_6097.JPG';
import img5 from '../assets/Gallery/Hotel/DSC_6100.JPG';
import img6 from '../assets/Gallery/Hotel/DSC_6674.JPG';
import img7 from '../assets/Gallery/Hotel/DSC_6682.JPG';
import img8 from '../assets/Gallery/Hotel/DSC_6687.JPG';
import img9 from '../assets/Gallery/Hotel/DSC_6690.JPG';
import img10 from '../assets/Gallery/Hotel/DSC_6701.JPG';
import img11 from '../assets/Gallery/SwimmingPool/DSC_6180.JPG';
import img12 from '../assets/Gallery/SwimmingPool/DSC_6182.JPG';
import img13 from '../assets/Gallery/SwimmingPool/DSC_6181.JPG';
import img14 from '../assets/Gallery/Anuradapura/Anuradhapura-Ancient-City.jpg';
import img15 from '../assets/Gallery/Anuradapura/Ruwanwelisaya-Stupa-Anuradhapura-golden-triangle-sri-lanka.jpg';
import img16 from '../assets/Gallery/Anuradapura/anuradhapura3.jpg';
import img17 from '../assets/Gallery/Anuradapura/474494022.jpg';
import img18 from '../assets/Gallery/Polonnaruwa/59.jpg';
import img19 from '../assets/Gallery/Polonnaruwa/92512507.jpg';
import img20 from '../assets/Gallery/Polonnaruwa/istockphoto-1071375364-612x612.jpg';
import img21 from '../assets/Gallery/Polonnaruwa/large-LKI9500066-E-1587026695587.jpg';
import img22 from '../assets/Gallery/Facilities/DSC_6117.JPG';
import img23 from '../assets/Gallery/Facilities/DSC_6124.JPG';
import img24 from '../assets/Gallery/Facilities/DSC_6202.JPG';
import img25 from '../assets/Gallery/Facilities/DSC_6198.JPG';
import img26 from '../assets/Gallery/Facilities/DSC_6206.JPG';
import img27 from '../assets/Gallery/Facilities/DSC_6126.JPG';
import img28 from '../assets/Gallery/Facilities/DSC_6131.JPG';
import img29 from '../assets/Gallery/Facilities/DSC_6137.JPG';
import img30 from '../assets/Gallery/Sigiriya/1000_F_159747281_eB0QZHoRt7kRr8id3uJpb6BJIg3weEx8.jpg';
import img31 from '../assets/Gallery/Sigiriya/Lion-Rock-Sigiriya-Fortress-Sigiriya-Sri-Lanka.jpg';
import img32 from '../assets/Gallery/Waterfalls/15-Must-Visit-Waterfalls-in-Sri-Lanka.jpg';
import img33 from '../assets/Gallery/Waterfalls/DRG_0971-min.width-800.jpg';
import img34 from '../assets/Gallery/Waterfalls/1699861948733.jpeg';
import img35 from '../assets/Gallery/Hotel/DSC_6176.JPG';
import img36 from '../assets/Gallery/Hotel/DSC_6173.JPG';
import img37 from '../assets/Gallery/Hotel/DSC_6694.JPG';
import img38 from '../assets/Gallery/Hotel/DSC_6690.JPG';
import img39 from '../assets/Gallery/beach/b1.jpg';
import img40 from '../assets/Gallery/beach/b2.jpg';
import img41 from '../assets/Gallery/beach/b3.jpg';
import img42 from '../assets/Gallery/kandy/k1.jpg';
import img43 from '../assets/Gallery/kandy/k2.jpeg';
import img44 from '../assets/Gallery/kandy/k3.webp';
import img45 from '../assets/Gallery/31/DSC_6226.JPG';
import img46 from '../assets/Gallery/31/DSC_6215.JPG';
import img47 from '../assets/Gallery/31/DSC_6219.JPG';
import img48 from '../assets/Gallery/31/DSC_6222.JPG';
import img49 from '../assets/Gallery/31/DSC_6227.JPG';
import img50 from '../assets/Gallery/31/DSC_6228.JPG';
import img51 from '../assets/Gallery/31/DSC_6229.JPG';
import img52 from '../assets/Gallery/31/DSC_6230.JPG';
import img53 from '../assets/Gallery/31/DSC_6231.JPG';
import img54 from '../assets/Gallery/31/DSC_6232.JPG';
import img55 from '../assets/Gallery/31/DSC_6233.JPG';
import img56 from '../assets/Gallery/31/DSC_6234.JPG';
import img57 from '../assets/Gallery/31/DSC_6235.JPG';
import img58 from '../assets/Gallery/hill/h1.jpg';
import img59 from '../assets/Gallery/hill/h2.jpg';
import img60 from '../assets/Gallery/hill/h3.jpg';
import img61 from '../assets/Gallery/treatment/treatment.jpg';
import img62 from '../assets/Gallery/christ/1-copy-2-480x480.jpg';
import img63 from '../assets/Gallery/christ/christ.JPG';
import img64 from '../assets/Gallery/htour/ht1.jpg';
import img65 from '../assets/Gallery/htour/ht2.jpg';
import img66 from '../assets/Gallery/ntour/nt1.jpg';
import img67 from '../assets/Gallery/ntour/nt2.png';
import img68 from '../assets/Gallery/tour/t1.jpg';
import img69 from '../assets/Gallery/tour/t2.jpg';
import img70 from '../assets/Gallery/tour/t3.jpg';
import img71 from '../assets/Gallery/Hotel/elephant.jpeg';
import img72 from '../assets/Gallery/Hotel/WhatsApp Image 2024-08-13 at 12.49.42.jpeg';
import img73 from '../assets/Gallery/Hotel/WhatsApp Image 2024-08-13 at 12.49.43.jpeg';

// Static images array
const staticImages = [
    { src: img1, category: 'HOTEL' },
    { src: img2, category: 'HOTEL' },
    { src: img3, category: 'HOTEL' },
    { src: img4, category: 'HOTEL' },
    { src: img5, category: 'HOTEL' },
    { src: img6, category: 'HOTEL' },
    { src: img7, category: 'HOTEL' },
    { src: img8, category: 'HOTEL' },
    { src: img9, category: 'HOTEL' },
    { src: img10, category: 'HOTEL' },
    { src: img11, category: 'SWIMMING POOL' },
    { src: img12, category: 'SWIMMING POOL' },
    { src: img13, category: 'SWIMMING POOL' },
    { src: img14, category: 'ANURADAPURA' },
    { src: img15, category: 'ANURADAPURA' },
    { src: img16, category: 'ANURADAPURA' },
    { src: img17, category: 'ANURADAPURA' },
    { src: img18, category: 'POLONNARUWA' },
    { src: img19, category: 'POLONNARUWA' },
    { src: img20, category: 'POLONNARUWA' },
    { src: img21, category: 'POLONNARUWA' },
    { src: img22, category: 'FACILITIES' },
    { src: img23, category: 'FACILITIES' },
    { src: img24, category: 'FACILITIES' },
    { src: img25, category: 'FACILITIES' },
    { src: img26, category: 'FACILITIES' },
    { src: img27, category: 'FACILITIES' },
    { src: img28, category: 'FACILITIES' },
    { src: img29, category: 'FACILITIES' },
    { src: img30, category: 'SIGIRIYA' },
    { src: img31, category: 'SIGIRIYA' },
    { src: img32, category: 'WATERFALLS' },
    { src: img33, category: 'WATERFALLS' },
    { src: img34, category: 'WATERFALLS' },
    { src: img35, category: 'HOTEL' },
    { src: img36, category: 'HOTEL' },
    { src: img37, category: 'HOTEL' },
    { src: img38, category: 'HOTEL' },
    { src: img39, category: 'SRI LANKA BEACH' },
    { src: img40, category: 'SRI LANKA BEACH' },
    { src: img41, category: 'SRI LANKA BEACH' },
    { src: img42, category: 'KANDY' },
    { src: img43, category: 'KANDY' },
    { src: img44, category: 'KANDY' },
    { src: img45, category: 'DECEMBER 31ST NIGHT' },
    { src: img46, category: 'DECEMBER 31ST NIGHT' },
    { src: img47, category: 'DECEMBER 31ST NIGHT' },
    { src: img48, category: 'DECEMBER 31ST NIGHT' },
    { src: img49, category: 'DECEMBER 31ST NIGHT' },
    { src: img50, category: 'DECEMBER 31ST NIGHT' },
    { src: img51, category: 'DECEMBER 31ST NIGHT' },
    { src: img52, category: 'DECEMBER 31ST NIGHT' },
    { src: img53, category: 'DECEMBER 31ST NIGHT' },
    { src: img54, category: 'DECEMBER 31ST NIGHT' },
    { src: img55, category: 'DECEMBER 31ST NIGHT' },
    { src: img56, category: 'DECEMBER 31ST NIGHT' },
    { src: img57, category: 'DECEMBER 31ST NIGHT' },
    { src: img58, category: 'HILL COUNTRY' },
    { src: img59, category: 'HILL COUNTRY' },
    { src: img60, category: 'HILL COUNTRY' },
    { src: img61, category: 'AYURVEDA TREATMENT CENTER' },
    { src: img62, category: 'CHRISTMAS' },
    { src: img63, category: 'CHRISTMAS' },
    { src: img64, category: 'HISTORICAL TOUR' },
    { src: img65, category: 'HISTORICAL TOUR' },
    { src: img66, category: 'NATURE TOUR' },
    { src: img67, category: 'NATURE TOUR' },
    { src: img68, category: 'TOURS' },
    { src: img69, category: 'TOURS' },
    { src: img70, category: 'TOURS' },
    { src: img71, category: 'HOTEL' },
    { src: img72, category: 'HOTEL' },
    { src: img73, category: 'HOTEL' },
    // ... Add additional images if needed
];

const categories = [
    'ALL', 'ANURADAPURA', 'AYURVEDA TREATMENT CENTER', 'CHRISTMAS',
    'DECEMBER 31ST NIGHT', 'FACILITIES', 'HILL COUNTRY',
    'HISTORICAL TOUR', 'HOTEL', 'KANDY', 'NATURE TOUR',
    'POLONNARUWA', 'SIGIRIYA', 'SRI LANKA BEACH',
    'SWIMMING POOL', 'TOURS', 'WATERFALLS'
];

function Gallery() {
    const [selectedCategory, setSelectedCategory] = useState('ALL');
    const [newImages, setNewImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch('http://localhost/hotel-bougainvilla/hotel-admin/backend/fetch_images.php');
                const data = await response.json();
                setNewImages(data.map(img => ({
                    ...img,
                    src: `http://localhost${img.src}` // Ensure this path is correct
                })));
            } catch (error) {
                console.error('Error fetching new images:', error);
            }
        };

        fetchImages();
    }, []);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const allImages = [...staticImages, ...newImages];

    const filteredImages = selectedCategory === 'ALL'
        ? allImages
        : allImages.filter(image => image.category === selectedCategory);

    return (
        <div className="gallery-top-section">
            <Navbar />
            <div className="gallery-image-container">
                <img src={galleryImage} alt="Gallery" className="gallery-image" />
                <h1 className="gallery-heading">OUR GALLERY</h1>
            </div>

            <div className="gallery-buttons">
                {categories.map(category => (
                    <button
                        key={category}
                        className={`gallery-button ${selectedCategory === category ? 'active' : ''}`}
                        onClick={() => handleCategoryClick(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>

            <div className="gallery-grid">
                {filteredImages.map((image, index) => (
                    <img
                        key={index}
                        src={image.src}
                        alt={image.category}
                        className="gallery-item"
                    />
                ))}
            </div>
            <Footer />
        </div>
    );
}

export default Gallery;