import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css';
import hotelLogo from '../../assets/Navbar/Bougainvilla.png'; // Update this path based on your directory structure
import { useAuth } from '../../components/AuthContext'; // Import useAuth from AuthContext

function AdminLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { login } = useAuth(); // Get the login function from AuthContext

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await fetch('http://localhost/hotel-bougainvilla/hotel-admin/backend/login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();
            if (data.success) {
                login(); // Update the auth state to logged in
                navigate('/admin/dashboard'); // Navigate to the dashboard
            } else {
                setError(data.error || 'Login failed!'); // Display error if login fails
            }
        } catch (err) {
            setError('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="admin-login-container">
            <div className="admin-login-left">
                <img src={hotelLogo} alt="Hotel Logo" />
                <h2>HOTEL BOUGAINVILLA</h2>
            </div>
            <div className="admin-login-right">
                <div className="admin-login-box">
                    <h1 className="admin-login-title">ADMIN LOGIN</h1>
                    <form className="admin-login-form" onSubmit={handleLogin}>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="admin-login-input"
                            required
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="admin-login-input"
                            required
                        />
                        {error && <p className="error-message">{error}</p>} {/* Display error message */}
                        <p className="forgot-password">Forgot Password?</p>
                        <button type="submit" className="admin-login-button">Login</button>
                    </form>
                    <div className="admin-login-link">
                        <p>Don't have an account? <a href="/admin/register">Register here</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;
