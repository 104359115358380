// /Applications/XAMPP/xamppfiles/htdocs/hotel-bougainvilla/src/App.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';

// Import public pages
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Rooms from './pages/Rooms';
import RoomsList from './pages/RoomsList';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import Reviews from './pages/Reviews';
import ContactUs from './pages/ContactUs';

// Import room pages
import StandardSingleRoomBB from './pages/rooms/StandardSingleRoomBB';
import StandardSingleRoomHB from './pages/rooms/StandardSingleRoomHB';
import StandardSingleRoomFB from './pages/rooms/StandardSingleRoomFB';
import StandardDoubleRoomBB from './pages/rooms/StandardDoubleRoomBB';
import StandardDoubleRoomHB from './pages/rooms/StandardDoubleRoomHB';
import StandardDoubleRoomFB from './pages/rooms/StandardDoubleRoomFB';
import StandardTripleRoomBB from './pages/rooms/StandardTripleRoomBB';
import StandardTripleRoomHB from './pages/rooms/StandardTripleRoomHB';
import StandardTripleRoomFB from './pages/rooms/StandardTripleRoomFB';
import LuxurySingleRoomBB from './pages/rooms/LuxurySingleRoomBB';
import LuxurySingleRoomHB from './pages/rooms/LuxurySingleRoomHB';
import LuxurySingleRoomFB from './pages/rooms/LuxurySingleRoomFB';
import LuxuryDoubleRoomBB from './pages/rooms/LuxuryDoubleRoomBB';
import LuxuryDoubleRoomHB from './pages/rooms/LuxuryDoubleRoomHB';
import LuxuryDoubleRoomFB from './pages/rooms/LuxuryDoubleRoomFB';
import LuxuryTripleRoomBB from './pages/rooms/LuxuryTripleRoomBB';
import LuxuryTripleRoomHB from './pages/rooms/LuxuryTripleRoomHB';
import LuxuryTripleRoomFB from './pages/rooms/LuxuryTripleRoomFB';
import ApartmentTripleRoomFB from './pages/rooms/ApartmentTripleRoomFB';
import ApartmentTripleRoomHB from './pages/rooms/ApartmentTripleRoomHB';
import ApartmentTripleRoomBB from './pages/rooms/ApartmentTripleRoomBB';
import ApartmentDoubleRoomFB from './pages/rooms/ApartmentDoubleRoomFB';
import ApartmentDoubleRoomHB from './pages/rooms/ApartmentDoubleRoomHB';
import ApartmentDoubleRoomBB from './pages/rooms/ApartmentDoubleRoomBB';
import ApartmentSingleRoomFB from './pages/rooms/ApartmentSingleRoomFB';
import ApartmentSingleRoomHB from './pages/rooms/ApartmentSingleRoomHB';
import ApartmentSingleRoomBB from './pages/rooms/ApartmentSingleRoomBB';

// Import other pages
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Sitemap from './pages/Sitemap';

// Import booking pages
import Search from './pages/booking/Search';
import Booking from './pages/booking/Booking';
import Checkout from './pages/booking/Checkout';
import Confirmation from './pages/booking/Confirmation';

// Import admin pages
import AdminLogin from './pages/admin/AdminLogin';
import AdminRegister from './pages/admin/AdminRegister';
import AdminDashboard from './pages/admin/AdminDashboard';
import GalleryUpdate from './pages/admin/GalleryUpdate'; // Correct path to GalleryUpdate


// Import ProtectedRoute
import ProtectedRoute from './components/ProtectedRoute';

// Import ParentComponent
import ParentComponent from './components/ParentComponent'; // Correct path to ParentComponent


function App() {
  return (
    <main className="container">
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/rooms" element={<Rooms />}>
          <Route path="rooms-list" element={<RoomsList />} />
          <Route path="services" element={<Services />} />
        </Route>
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/contact-us" element={<ContactUs />} />

        {/* Room Pages */}
        <Route path="/rooms/standard-single-bb" element={<StandardSingleRoomBB />} />
        <Route path="/rooms/standard-single-hb" element={<StandardSingleRoomHB />} />
        <Route path="/rooms/standard-single-fb" element={<StandardSingleRoomFB />} />
        <Route path="/rooms/standard-double-bb" element={<StandardDoubleRoomBB />} />
        <Route path="/rooms/standard-double-hb" element={<StandardDoubleRoomHB />} />
        <Route path="/rooms/standard-double-fb" element={<StandardDoubleRoomFB />} />
        <Route path="/rooms/standard-triple-bb" element={<StandardTripleRoomBB />} />
        <Route path="/rooms/standard-triple-hb" element={<StandardTripleRoomHB />} />
        <Route path="/rooms/standard-triple-fb" element={<StandardTripleRoomFB />} />
        <Route path="/rooms/luxury-single-bb" element={<LuxurySingleRoomBB />} />
        <Route path="/rooms/luxury-single-hb" element={<LuxurySingleRoomHB />} />
        <Route path="/rooms/luxury-single-fb" element={<LuxurySingleRoomFB />} />
        <Route path="/rooms/luxury-double-bb" element={<LuxuryDoubleRoomBB />} />
        <Route path="/rooms/luxury-double-hb" element={<LuxuryDoubleRoomHB />} />
        <Route path="/rooms/luxury-double-fb" element={<LuxuryDoubleRoomFB />} />
        <Route path="/rooms/luxury-triple-bb" element={<LuxuryTripleRoomBB />} />
        <Route path="/rooms/luxury-triple-hb" element={<LuxuryTripleRoomHB />} />
        <Route path="/rooms/luxury-triple-fb" element={<LuxuryTripleRoomFB />} />
        <Route path="/rooms/apartment-triple-fb" element={<ApartmentTripleRoomFB />} />
        <Route path="/rooms/apartment-triple-hb" element={<ApartmentTripleRoomHB />} />
        <Route path="/rooms/apartment-triple-bb" element={<ApartmentTripleRoomBB />} />
        <Route path="/rooms/apartment-double-fb" element={<ApartmentDoubleRoomFB />} />
        <Route path="/rooms/apartment-double-hb" element={<ApartmentDoubleRoomHB />} />
        <Route path="/rooms/apartment-double-bb" element={<ApartmentDoubleRoomBB />} />
        <Route path="/rooms/apartment-single-fb" element={<ApartmentSingleRoomFB />} />
        <Route path="/rooms/apartment-single-hb" element={<ApartmentSingleRoomHB />} />
        <Route path="/rooms/apartment-single-bb" element={<ApartmentSingleRoomBB />} />

        {/* Other Pages */}
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/sitemap" element={<Sitemap />} />

        {/* Booking Pages */}
        <Route path="/booking" element={<Search />} />
        <Route path="/booking/booking" element={<Booking />} />
        <Route path="/booking/checkout" element={<Checkout />} />
        <Route path="/booking/confirmation" element={<Confirmation />} />

        {/* Admin Pages */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/register" element={<AdminRegister />} />
        <Route path="/admin/dashboard" element={<ProtectedRoute element={<AdminDashboard />} />} />
        <Route path="/admin/gallery-update" element={<ProtectedRoute element={<GalleryUpdate />} />} />


        {/* Parent Component Route */}
        <Route path="/parent" element={<ParentComponent />} />
      </Routes>
    </main>
  );
}

export default App;
